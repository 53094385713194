import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import styled, { ThemeProvider } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import { GlobalStyle } from './globalStyle';
import theme from '../theme';
import SEO from './seo';
import { Stack, H, Text, P, Center, Cluster } from './system';
import { Container } from '../components/layoutComponents';
import SignupForm from '../components/SignupForm';
import Footer from './footer';
import { InternalLink, ExternalLink } from './Links';
import { breakpoint } from '../constants';
import beerFinderLogo from '../images/logo--name.svg';
import './layout.css';
import { SignupFormContext } from '../contexts/signup-form';
import { useScrollPercentage } from 'react-scroll-percentage';

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        metaDescription
        author
        path
        socialImage {
          id
          childImageSharp {
            id
            resize {
              src
            }
          }
        }
        date(formatString: "MMMM Do, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        images {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const shortcodes = {
  h1: props => <H as="h2" size="lg" {...props} />,
  h2: props => <H as="h3" size="md" {...props} />,
  h3: props => <H as="h4" size="sm" {...props} />,
  a: ExternalLink,
  p: P,
  wrapper: props => {
    return <Stack {...props} />;
  },
  InternalLink,
  Img: props => {
    return (
      <div>
        <Stack xs>
          <Img
            alt={props.caption}
            css={`
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
            `}
            fluid={props?.src?.childImageSharp?.fluid}
          />
          {props.caption && (
            <Text as="div" textColor="greyDarker">
              {props.caption}
            </Text>
          )}
        </Stack>
      </div>
    );
  },
};

// TODO: maybe include something like: Last updated: May 4, 2020

function LayoutPost({ data: { mdx } }) {
  const {
    frontmatter: {
      title,
      metaDescription,
      date,
      author,
      path,
      featuredImage,
      socialImage,
    },
  } = mdx;
  const { isAllowedToPopUp, setIsOpen } = useContext(SignupFormContext);
  const [scrollRef, percentage] = useScrollPercentage();

  useEffect(() => {
    if (percentage > 0.75 && isAllowedToPopUp) {
      setIsOpen(true);
    }
  }, [isAllowedToPopUp, percentage, setIsOpen, socialImage]);

  const featuredImgFluid = featuredImage?.childImageSharp?.fluid;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SEO
        title={title}
        path={`/blog/${path}/`}
        description={metaDescription}
        socialImage={socialImage?.childImageSharp?.resize?.src}
      />
      <Nav>
        <Container>
          <Stack>
            <Cluster
              css={`
                justify-content: space-between;
                align-items: center;
              `}
            >
              <InternalLink
                noStyles
                css={`
                  color: white;
                `}
                to="/"
              >
                <picture
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <source
                    srcSet={beerFinderLogo}
                    media={`(min-width: ${breakpoint.sm})`}
                  />
                  <Logo
                    css={`
                      cursor: pointer;
                      min-height: 50px;
                    `}
                    srcSet={beerFinderLogo}
                    alt="Beer Finder logo"
                  />
                </picture>
              </InternalLink>
              <div
                css={`
                  @media (min-width: ${breakpoint.sm}) {
                    justify-self: start;
                    display: inline-block;
                    margin-left: 40px;
                  }
                `}
              >
                <InternalLink
                  noStyles
                  css={`
                    border-bottom: 3px solid ${p => p.theme.colors.white};
                    :hover {
                      border-bottom: 3px solid ${p => p.theme.colors.contrast};
                    }
                  `}
                  to="/blog"
                >
                  <Text textColor="white">
                    <strong>Blog</strong>
                  </Text>
                </InternalLink>
              </div>
            </Cluster>
          </Stack>
        </Container>
      </Nav>
      <div
        css={`
          position: absolute;
          z-index: -10;
          width: 100%;
          height: 20vh;
          background: ${p => p.theme.colors.primaryLight};

          :after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            border-bottom: 40px solid ${p => p.theme.colors.primaryLighter};
          }

          @media (min-width: ${breakpoint.sm}) {
            height: 40vh;
          }
        `}
      />
      <Container
        css={`
          padding-left: 0;
          padding-right: 0;

          @media (min-width: ${breakpoint.sm}) {
            padding-top: 20px;
            padding-bottom: 100px;
          }
        `}
      >
        <Center>
          <ContentBlock>
            <Stack>
              {featuredImgFluid && <Img css={``} fluid={featuredImgFluid} />}
              <Stack lg>
                <Stack sm>
                  <H
                    as="h1"
                    css={`
                      padding-bottom: 10px;
                      border-bottom: 4px solid ${p => p.theme.colors.contrast};
                    `}
                    textColor="primary"
                    size={'xl'}
                  >
                    {title}
                  </H>
                  <div
                    css={`
                      flex-direction: column;
                      display: flex;
                      justify-content: space-between;

                      @media (min-width: ${breakpoint.sm}) {
                        flex-direction: row;
                      }
                    `}
                  >
                    <div>
                      Written by: <strong>{author}</strong>
                    </div>
                    <div>
                      Published on: <i>{date}</i>
                    </div>
                  </div>
                </Stack>
                <div ref={scrollRef}>
                  <MDXProvider components={shortcodes}>
                    <MDXRenderer frontmatter={mdx.frontmatter}>
                      {mdx.body}
                    </MDXRenderer>
                  </MDXProvider>
                </div>
              </Stack>
            </Stack>
          </ContentBlock>
        </Center>
      </Container>
      <Footer />
      <SignupForm />
    </ThemeProvider>
  );
}

export default LayoutPost;

const Logo = styled.img`
  max-width: 150px;
  @media (min-width: ${breakpoint.sm}) {
    max-width: 200px;
  }
`;

const Nav = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${p => p.theme.colors.black};

  @media (min-width: ${breakpoint.sm}) {
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
  }
`;

const ContentBlock = styled.div`
  max-width: 100%;
  z-index: 10;

  background: white;
  padding: 20px;
  padding-bottom: 80px;

  @media (min-width: 800px) {
    padding: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    max-width: 640px;
  }
`;
